import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Title from "../../../../../components/title";
import Button from "../../../../../components/button";
import Spinner from "../../../../../components/spinner";
import Input from "../../../../../components/input";
import Select from "../../../../../components/select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExclamationTriangle, faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {monthsOptions, sleep} from "../../../../../theme/theme";
import {getAllLeasingPrice, getAllLoanPrice, getRate} from "../../../../../theme/calculator";
import CheckBox from "../../../../../components/checkbox";
import PDFGenerator from "../offerGenerator/pdfGenerator";
import RentPdf from "../../../../pdf/rent";
import DatePicker from "../../../../../components/datePicker";
import offers from "../../../../../redux/reducers/offers";
import {PDFDownloadLink, usePDF} from "@react-pdf/renderer";
import {monthDiff} from "../../../../../utils/dateHelper";
import {db} from "../../../../../firebase/firebase";
import { useSelector } from "react-redux";
import { Flex } from "../../../../../theme/styledComponents";
import { makeid } from "../../../../../theme/cookies";
import AllTemplates from "./allTemplates";

const RentGenerator = () => {
    const timeoutId = useRef()
    const download = useRef()
    const user = useSelector(state => state.auth.user)
    const [dbLoading,setDbLoading] = useState(false);
    const [allContracts,setAllContracts] = useState([]);
    const [modal,setModal] = useState(false);
    const [loading,setLoading] = useState(false)
    const [isNumberUpdated,setIsNumberUpdated] = useState(false)
    const [state,setState] = useState({
        id:'',
        number:'',
        company:'My Rent A Car Sp. z o.o.',
        reprezentant:'Marcin Młynarczyk',
        clientType:'Konsument',
        clientSex:'Panem',
        clientSex2:'Pana',
        clientName:'',
        clientPesel:'',
        city:'',
        clientPhoneNumber:'',
        companyName:'',
        companyRegon:'',
        companyNip:'',
        companyKrs:'',
        adress:'',
        postCode:'',
        brand:'',
        model:'',
        year:'',
        vin:'',
        carPrice:'',
        plateNumber:'',
        contractTime:'określony',
        contractTimeFrom:'',
        contractTimeTo:'',
        realaseDate:'',
        realasePlace:'Inowrocław, ul. Wodna 15',
        additionalClientSex:'Pan',
        additionalClientName:'',
        additionalClientPesel:'',
        returnPlace:'Inowrocław, ul. Wodna 15',
        returnPerson:'',
        czynsz:'',
        czynszZGory:'',
        oplataOutsideUE:'',
        oplataOutsidePL:'',
        oplataZaInnaOsobeL:'',
        oplataZa25Lat:'',
        oplataZaNadprzebieg:'',
        naruszenie9i10:'',
        signContractFrom:'',
        signContractTo:'',
        oplataZaPrzedluzenie:'',
        kaucja:'',
        email:'',
    })

    const document = <RentPdf state={state}/>;

    const [instance, updateInstance] = usePDF({ document: document});

    const onChangeNumber = useCallback((e) => {
        if (/^[0-9]*(,|,([0-9]|[0-9][0-9]))?$/.test(e.target.value)) {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }
    }, [state])

    const onChange = useCallback((e) => {
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    }, [state])

    useEffect(() => {
        const array = [];
        db.collection('rentContracts')
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    const item =  doc.data();
                    array.push({
                        id:item.id,
                        name:item.name,
                        object:JSON.parse(item.object)
                    })
                });
                setAllContracts(array);
            })
            .catch(function(error) {
                console.log("Error getting rent contracts: ", error);
            });
        //
        // db.collection('configuration').doc('myrent')
        //     .get()
        //     .then(async (doc) => {
        //         const n = doc.data().contract;
        //
        //         setState({
        //             ...state,
        //             number:n
        //         })
        //     })

    }, [])

    useEffect(() => {
        setLoading(true)
        if (timeoutId.current) {
            clearTimeout(timeoutId.current)
        }

        timeoutId.current = setTimeout(() => {
            updateInstance()
            setTimeout(() => {
                setLoading(false)
            }, 500)
        }, 1000)
    }, [state])


    const onUpdateContract = () => {
        setDbLoading(true);

        const name = `Umowa nr. ${state.number}`;
        db.collection('rentContracts').doc(state.id).update({
            name,
            object: JSON.stringify(state)
        })
            .then(response => {
                const array = allContracts;
                const index = array.findIndex(item => item.id === state.id);
                array[index].name = name;
                array[index].object = state;
                setAllContracts(array);
                setDbLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDbLoading(false);
            })
    };

    const onAddContract = () => {
        const id = makeid(20);
        const object = {
            id,
            user: user.uid,
            name:`Umowa nr. ${state.number}`,
            object: JSON.stringify({
                ...state,
                id,
            })
        }

        db.collection('rentContracts').doc(id).set(object)
            .then(response => {
                const array = allContracts;
                array.push(object)
                setState(object.object)
                setAllContracts(array);
            })
            .catch(err => {
                console.log(err);
                setDbLoading(false);
            })
    };

    const chooseTemplate = (id) => {
        const template = allContracts.find(item => item.id === id);
        console.log(template)
        if(template){
            setState(template.object);
        }
    };

    return (
        <>
            <Wrapper>
                <ContentHeader>
                    <Title>Generator umowy</Title>
                    <Flex>
                        <Button secondary background="white" margin="0 10px 0 0" onClick={() => setModal('all-contracts')}>
                            Otwórz
                        </Button>
                        {
                            state.id ?
                                <Button
                                    onClick={onUpdateContract}
                                >
                                    {
                                        dbLoading ?
                                            <Spinner borderWidth={3} white width={10} height={10}/> :
                                            <>Aktualizuj</>
                                    }
                                </Button> :
                                <Button onClick={onAddContract}>
                                    Zapisz nowy
                                </Button>
                        }
                    </Flex>
                </ContentHeader>
                <ContentBody>
                    <Block>
                        <Grid gtc="1fr 1fr">
                            <Input label="Numer umowy" name="number" value={state.number} onChange={(e) => onChangeNumber(e,"number")}/>
                        </Grid>
                    </Block>
                    <Block>
                        <Grid gtc="1fr 1fr">
                            <Select label="Firma" maxWidth="none" options={['My Rent A Car Sp. z o.o.','MK Sp. z o.o.']} name="company" value={state.company} onChange={(e) => setState({...state,company:e.target.value})}/>
                            <Select label="Reprezentant" maxWidth="none" options={['Marcin Młynarczyk','Mariusz Kołecki','Adam Bauza', 'Joanna Zygmunt']} name="reprezentant" value={state.reprezentant} onChange={(e) => setState({...state,reprezentant:e.target.value})}/>
                        </Grid>
                    </Block>
                    <Block>
                        <Title third>Klient</Title>
                        <Grid gtc="1fr 1fr 1fr 1fr">
                            <Select label="Rodzaj klienta" maxWidth="none" options={['Konsument','Działalność gospodarcza','Spółka']} name="clientType" value={state.clientType} onChange={(e) => setState({...state,clientType:e.target.value})}/>
                            {
                                state.clientType === 'Konsument' &&
                                <>
                                    <Select label="Panem/Panią" maxWidth="none" options={['Panem','Panią']} name="clientSex" value={state.clientSex} onChange={(e) => setState({...state,clientSex:e.target.value})}/>
                                    <Input label="Imię i nazwisko" info={`[...], a ${state.clientSex} ${state.clientName}`} name="clientName" value={state.clientName} onChange={(e) => setState({...state,clientName:e.target.value})}/>
                                    <Input label="PESEL" name="clientPesel" value={state.clientPesel} onChange={(e) => setState({...state,clientPesel:e.target.value})}/>
                                    <Input label="Nr telefonu" name="clientPhoneNumber" value={state.clientPhoneNumber} onChange={(e) => setState({...state,clientPhoneNumber:e.target.value})}/>
                                    <Input label="Adres" name="adress" value={state.adress} onChange={(e) => setState({...state,adress:e.target.value})}/>
                                    <Input label="Miasto" name="city" value={state.city} onChange={(e) => setState({...state,city:e.target.value})}/>
                                    <Input label="Kod pocztowy" name="postCode" value={state.postCode} onChange={(e) => setState({...state,postCode:e.target.value})}/>
                                </>
                            }
                            {
                                state.clientType === 'Działalność gospodarcza' &&
                                <>
                                    <Select label="Panem/Panią" maxWidth="none" options={['Panem','Panią']} name="clientSex" value={state.clientSex} onChange={(e) => setState({...state,clientSex:e.target.value})}/>
                                    <Input label="Imię i nazwisko" name="clientName"  info={`[...], a ${state.clientSex} ${state.clientName}`} value={state.clientName} onChange={(e) => setState({...state,clientName:e.target.value})}/>
                                    <Input label="Nazwa firmy" name="companyName" value={state.companyName} onChange={(e) => setState({...state,companyName:e.target.value})}/>
                                    <Input label="REGON" name="companyRegon" value={state.companyRegon} onChange={(e) => setState({...state,companyRegon:e.target.value})}/>
                                    <Input label="NIP" name="companyNip" value={state.companyNip} onChange={(e) => setState({...state,companyNip:e.target.value})}/>
                                    <Input label="Adres" name="adress" value={state.adress} onChange={(e) => setState({...state,adress:e.target.value})}/>
                                    <Input label="Miasto" name="city" value={state.city} onChange={(e) => setState({...state,city:e.target.value})}/>
                                    <Input label="Kod pocztowy" name="postCode" value={state.postCode} onChange={(e) => setState({...state,postCode:e.target.value})}/>
                                </>
                            }
                            {
                                state.clientType === 'Spółka' &&
                                <>
                                    <Select label="Pana/Panią" maxWidth="none" options={['Pana','Panią']} name="clientSex2" value={state.clientSex2} onChange={(e) => setState({...state,clientSex2:e.target.value})}/>
                                    <Input label="Imię i nazwisko" info={`[...] reprezentowaną przez ${state.clientSex2} ${state.clientName}`}  name="clientName" value={state.clientName} onChange={(e) => setState({...state,clientName:e.target.value})}/>
                                    <Input label="Nazwa firmy" name="companyName" value={state.companyName} onChange={(e) => setState({...state,companyName:e.target.value})}/>
                                    <Input label="REGON" name="companyRegon" value={state.companyRegon} onChange={(e) => setState({...state,companyRegon:e.target.value})}/>
                                    <Input label="NIP" name="companyNip" value={state.companyNip} onChange={(e) => setState({...state,companyNip:e.target.value})}/>
                                    <Input label="KRS" name="companyKrs" value={state.companyKrs} onChange={(e) => setState({...state,companyKrs:e.target.value})}/>
                                    <Input label="Adres" name="adress" value={state.adress} onChange={(e) => setState({...state,adress:e.target.value})}/>
                                    <Input label="Miasto" name="city" value={state.city} onChange={(e) => setState({...state,city:e.target.value})}/>
                                    <Input label="Kod pocztowy" name="postCode" value={state.postCode} onChange={(e) => setState({...state,postCode:e.target.value})}/>
                                </>
                            }
                        </Grid>
                    </Block>
                    <Block>
                        <Title third>§1 Przedmiot umowy najmu</Title>
                        <Grid gtc="1fr 1fr 1fr 1fr">
                            <Input label="Marka" name="brand" value={state.brand} onChange={onChange}/>
                            <Input label="Model" name="model" value={state.model} onChange={onChange}/>
                            <Input label="Rok" name="year" value={state.year} onChange={onChange}/>
                            <Input label="VIN" name="vin" value={state.vin} onChange={onChange}/>
                            <Input label="Nr rejestracyjny" name="plateNumber" value={state.plateNumber} onChange={onChange}/>
                            <Input label="Wartość pojazdu" endAdornment="PLN" name="carPrice" value={state.carPrice} onChange={onChangeNumber}/>
                        </Grid>
                        <Grid gtc="1fr 1fr 1fr 1fr">
                            <Select label="Na czas" maxWidth="none" options={['określony','nieokreślony']} name="contractTime" value={state.contractTime} onChange={(e) => setState({...state,contractTime:e.target.value})}/>
                            {
                                state.contractTime === 'określony' &&
                                <>
                                    <DatePicker value={state.contractTimeFrom} type="datetime-local" label="Od dnia" onChange={(e) => setState({...state,contractTimeFrom:e.target.value,signContractFrom:e.target.value})} error={monthDiff(state.contractTimeFrom)}/>
                                    <DatePicker value={state.contractTimeTo} type="datetime-local" label="Do dnia" onChange={(e) => setState({...state,contractTimeTo:e.target.value, signContractTo:e.target.value})} error={monthDiff(state.contractTimeTo)}/>
                                </>
                            }
                        </Grid>
                    </Block>
                    <Block>
                        <Title third>§2 Wydanie i zwrot przedmiotu najmu</Title>
                        <Grid gtc="1fr 1fr">
                            <DatePicker value={state.realaseDate} label="Data wydania" onChange={(e) => setState({...state,realaseDate:e.target.value})}/>
                            <Select label="Miejsce wydania" maxWidth="none" options={['Katowice, ul Sowińskiego 4','Inowrocław, Wodna 15','Bydgoszcz, ul. Unii Lubelskiej 4c']} name="realasePlace" value={state.realasePlace} onChange={(e) => setState({...state,realasePlace:e.target.value})}/>
                        </Grid>
                        <Grid gtc="1fr 1fr">
                            <Input label="Opłata za korzystanie poza UE" endAdornment="PLN" name="oplataOutsideUE" value={state.oplataOutsideUE} onChange={(e) => onChangeNumber(e,"oplataOutsideUE")}/>
                            <Input label="Opłata za korzystanie poza PL" endAdornment="PLN" name="oplataOutsidePL" value={state.oplataOutsidePL}  onChange={(e) => onChangeNumber(e,"oplataOutsidePL")}/>
                            <Input label="Opłata za korzystanie przez inna osobe" endAdornment="PLN" name="oplataZaInnaOsobeL" value={state.oplataZaInnaOsobeL} onChange={(e) => onChangeNumber(e,"oplataZaInnaOsobeL")}/>
                        </Grid>
                        <Grid gtc="1fr 1fr 1fr">
                            <Select label="Pan/Pani" maxWidth="none" options={['Pan','Pani']} name="additionalClientSex" value={state.additionalClientSex} onChange={(e) => setState({...state,additionalClientSex:e.target.value})}/>
                            <Input label="Imię i nazwisko" name="clientName" value={state.additionalClientName} onChange={(e) => setState({...state,additionalClientName:e.target.value})}/>
                            <Input label="PESEL" name="additionalClientPesel" value={state.additionalClientPesel} onChange={(e) => setState({...state,additionalClientPesel:e.target.value})}/>
                        </Grid>
                        <Grid gtc="1fr 1fr">
                            <Input label="Opłata za mniej niż 25 lat" endAdornment="PLN" name="oplataZa25Lat" value={state.oplataZa25Lat} onChange={(e) => onChangeNumber(e,"oplataZa25Lat")}/>
                            <Input label="Opłata zanaruszenie punktów 9 i 10" endAdornment="PLN" name="naruszenie9i10" value={state.naruszenie9i10} onChange={(e) => onChangeNumber(e,"naruszenie9i10")}/>
                        </Grid>
                    </Block>
                    <Block>
                        <Title third>§3 Czas trwania umowy najmu i kaucja</Title>
                        <Grid gtc="1fr 1fr">
                            <DatePicker value={state.signContractFrom} type="datetime-local" label="Zawarcie umowy od" onChange={(e) => setState({...state,signContractFrom:e.target.value})} error={monthDiff(state.signContractFrom)}/>
                            <DatePicker value={state.signContractTo} type="datetime-local" label="Zawarcie umowy do" onChange={(e) => setState({...state,signContractTo:e.target.value})} error={monthDiff(state.signContractTo)}/>
                            <Select label="Miejsce zwrotu" maxWidth="none" options={['Katowice, ul Sowińskiego 4','Inowrocław, Wodna 15','Bydgoszcz, ul. Unii Lubelskiej 4c']} name="realasePlace" value={state.returnPlace} onChange={(e) => setState({...state,returnPlace:e.target.value})}/>
                            <Input label="Osoba upoważniona do zwrotu" name="returnPerson" value={state.returnPerson} onChange={(e) => setState({...state,returnPerson:e.target.value})}/>
                            <Input label="Opłata za przedłużenie" endAdornment="PLN" name="oplataZaPrzedluzenie" value={state.oplataZaPrzedluzenie} onChange={(e) => onChangeNumber(e,"oplataZaPrzedluzenie")}/>
                            <Input label="Kaucja" endAdornment="PLN" name="kaucja" value={state.kaucja} onChange={(e) => onChangeNumber(e,"kaucja")}/>
                        </Grid>
                    </Block>
                    <Block>
                        <Title third>§6 Czynsz najmu i płatności</Title>
                        <Grid gtc="1fr 1fr 1fr">
                            <Input label="Wysokość czynszu" endAdornment="PLN" name="czynsz" value={state.czynsz} onChange={(e) => onChangeNumber(e,"czynsz")}/>
                            <Input label="Wysokość czynszu z góry" endAdornment="PLN" name="czynszZGory" value={state.czynszZGory} onChange={(e) => onChangeNumber(e,"czynszZGory")}/>
                            <Input label="Email" name="email" value={state.email} onChange={(e) => setState({...state,email:e.target.value})}/>
                            <Input label="Limit KM" name="limitKM" endAdornment="km" value={state.limitKM} onChange={(e) => setState({...state,limitKM:e.target.value})}/>
                            <Input label="Opłata za nadprzebieg" name="oplataZaNadprzebieg" endAdornment="PLN" value={state.oplataZaNadprzebieg} onChange={(e) => setState({...state,oplataZaNadprzebieg:e.target.value})}/>
                        </Grid>
                    </Block>
                    {/*<PDFDownloadLink document={<RentPdf state={state}/>} fileName="oferta.pdf">*/}
                    {/*    <Button big>*/}
                    {/*        <FontAwesomeIcon icon={faDownload}/> Generuj umowę*/}
                    {/*    </Button>*/}
                    {/*</PDFDownloadLink>*/}

                    {
                        !loading ?
                            <a href={instance.url} target="_blank" download id="download_pdf" ref={download}>
                                <Button big margin="0 0 10px">
                                    <FontAwesomeIcon icon={faDownload}/> Generuj umowę
                                </Button>
                            </a> :
                            <Button big margin="0 0 10px">
                                <Spinner white width={10} height={10}/>
                            </Button>
                    }

                    <Grid gtc="1fr 1fr" margin="10px 0 0" gg="10px">
                        <a href="https://admin.myfleet.force4.pl/wp-content/uploads/2021/09/6b04380b67c55d6075bc2f0a9534f0cd.pdf" target="_blank" download>
                            <Button big secondary background="rgba(0,0,0,0.1)">Pobierz protokół wydania</Button>
                        </a>
                        <a href="https://admin.myfleet.force4.pl/wp-content/uploads/2021/08/11504ec85d0706f6378b24b1114b54df.pdf" target="_blank" download>
                            <Button big secondary background="rgba(0,0,0,0.1)">Pobierz protokół zwrotu</Button>
                        </a>
                    </Grid>
                </ContentBody>
            </Wrapper>
            {
                modal === 'all-contracts' &&
                <AllTemplates
                    chooseTemplate={chooseTemplate}
                    // deleteTemplate={deleteTemplate}
                    templates={allContracts}
                    onBack={() => setModal(false)}
                />
            }
        </>
    );
}

export default RentGenerator;

const Grid = styled.div`
  display:grid;
  grid-template-columns: ${({gtc}) => gtc || '1fr 1fr 1fr'};
  grid-gap:10px;
  margin-top: ${({margin}) => margin};
`;

const Block = styled.div`
  margin-bottom: 20px;
`;


const ContentBody = styled.div`
  background: white;
  flex: 1 1;
  min-height: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Wrapper = styled.div`

`;
