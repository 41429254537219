import React from 'react';
import styled from 'styled-components';
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer';
import {getPricesInString} from "../panel/worker/content/offerGenerator/functions";
import LOGO from "../../images/logo.png";
import {numberWithSpaces} from "../../theme/theme";
import OFFER_IMAGE from "../../images/offerImage.png";
import SRWIS from "../../images/car-repair.png";
import TIRES from "../../images/wheel.png";
import REPLACE_CAR from "../../images/replace-car.png";
import INSURANCE from "../../images/insurance.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import O1 from "../../images/partner/1.png";
import O2 from "../../images/partner/2.png";
import O3 from "../../images/partner/3.png";
// import O4 from "../../images/partner/4.png";
// import O5 from "../../images/partner/5.png";
// import O6 from "../../images/partner/6.png";

export const MyDocument = ({state,leasing}) => {
    let leasingInfo = getPricesInString(state.price,leasing.contribution.value,leasing.contribution.type,leasing.repurchase.value,leasing.repurchase.type);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <View style={styles.header}>
                        <View>
                            <Image source={LOGO} style={{margin:20,width:150}}/>
                        </View>
                        <View style={styles.worker}>
                            <View style={{width:'200%',height:'400%',backgroundColor:'rgb(54,118,181)',position:'absolute',left:-8,bottom:-20,borderRadius:10,transform:'rotate(10deg)'}}></View>

                            <Text style={styles.workerLabel}>Opiekun</Text>
                            <Text style={styles.workerText}>{state.user.name}</Text>
                            <Text style={styles.workerText}>{state.user.phone}</Text>
                            <Text style={styles.workerText}>{state.user.email}</Text>
                        </View>
                    </View>
                    <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <View style={[styles.car,{width:'50%'}]}>
                            <View style={[styles.carRow,{flexDirection:'column', alignItems:'flex-start',marginBottom:20}]}>
                                <Text style={[styles.carLabel]}>KLIENT:</Text>
                                <Text style={styles.carText}>{state.client}</Text>
                            </View>
                        </View>
                        <View style={{width:'50%',position:'relative',paddingTop:30}}>
                            <Image source={OFFER_IMAGE} style={{height:'200px',objectFit:'contain'}}/>
                        </View>
                    </View>

                    {
                        state.financeType === 'Leasing' &&
                        <>
                            <View style={{...styles.primaryFinance, position: 'relative', flexDirection: 'row-reverse'}}>
                                <View style={{
                                    position: 'absolute',
                                    left: '-100%',
                                    width: '100%',
                                    top: 0,
                                    height: '100%',
                                    backgroundColor: '#d9d9d9'
                                }}></View>
                                <View style={{
                                    padding: 10,
                                    justifyContent: 'center',
                                    backgroundColor: 'rgb(54,118,181)',
                                    borderBottomRightRadius: 8,
                                    borderTopRightRadius: 8,
                                    width: '25%'
                                }}>
                                    <Text style={styles.priceText}>{leasing.rate}</Text>
                                    <Text style={styles.priceSubText}>netto / mies.</Text>
                                </View>
                                <View style={{
                                    flexDirection: 'column',
                                    padding: 10,
                                    paddingRight: 20,
                                    paddingBottom: 15,
                                    overflow: 'hidden',
                                    paddingLeft: 0,
                                    backgroundColor: '#d9d9d9',
                                    width: '75%'
                                }}>
                                    <View>
                                        <View style={{flexDirection: 'row'}}>
                                            <View style={{width: '40%', marginRight: '2%'}}>
                                                <Text style={styles.financeLabel}>Radzaj umowy</Text>
                                                <View style={styles.radioWrapper}>
                                                    <View
                                                        style={[styles.radio,styles.radioActive]}>
                                                        <Text
                                                            style={[styles.radioText, state.financeType === 'Leasing' ? {color: 'white'} : '']}>{state.financeType}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{width: '58%'}}>
                                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <Text style={styles.financeLabel}>Okres umowy</Text>
                                                    <Text style={styles.financeLabel}></Text>
                                                </View>
                                                <View style={styles.radioWrapper}>
                                                    <View style={[styles.radio, styles.radioActive]}>
                                                        <Text
                                                            style={[styles.radioText, {color: 'white'}]}>{leasing.period} miesięcy</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{flexDirection: 'row', marginTop: 10}}>
                                            <View style={{width: '49%', marginRight: '2%'}}>
                                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <Text style={styles.financeLabel}>Wpłata</Text>
                                                    <Text
                                                        style={styles.financeLabel}>{numberWithSpaces(leasingInfo[0][0]).replaceAll(".", ",")} PLN {state.catalogPrice === state.price ? `(${leasingInfo[0][1]}%)` : ''}</Text>
                                                </View>
                                                <View style={styles.sliderWrapper}>
                                                    <View
                                                        style={[styles.slider, {width: `${!isNaN(leasingInfo[0][2]) ? leasingInfo[0][2] : 0}%`}]}>
                                                        <View style={styles.sliderDrag}/>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{width: '49%'}}>
                                                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                    <Text style={styles.financeLabel}>Wykup</Text>
                                                    <Text
                                                        style={styles.financeLabel}>{numberWithSpaces(leasingInfo[1][0]).replaceAll(".", ",")} PLN {state.catalogPrice === state.price ? `(${leasingInfo[1][1]}%)` : ''}</Text>
                                                </View>
                                                <View style={styles.sliderWrapper}>
                                                    <View
                                                        style={[styles.slider, {width: `${!isNaN(leasingInfo[1][2]) ? leasingInfo[1][2] : 0 }%`}]}>
                                                        <View style={styles.sliderDrag}/>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </>
                    }
                </View>
                <View>
                    <View style={styles.footer}>
                        <View>
                            <Text style={styles.footerText}>MyLease.pl</Text>
                            <Text style={styles.footerText}>ul. Wodna 15, 88-100 Inowrocław</Text>
                        </View>
                        <View>
                            <Text style={styles.footerText}><FontAwesomeIcon icon={faGlobeEurope}/>www.mylease.pl</Text>
                        </View>
                    </View>
                    <View style={styles.footer2}>
                        <Text style={styles.footer2Text}>Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłacznie informacyjny. Ostateczne warunki umowy zaleza od oceny zdolnosci leasingowej
                            Leasingobiorcy oraz finansowanego przedmiotu i zostana uregulowane w umowie leasingu.</Text>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={[styles.page,styles.page2]}>
                <View style={styles.header2}>
                    <View>
                        <Image source={LOGO} style={{margin:20,width:150}}/>
                    </View>
                </View>
                <View style={{flex: '1 1'}}>
                    <View style={{flex: '1 1',padding:'0px 20px 13px 20px'}}>
                        <View style={{flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O1} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O2} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O3} style={{objectFit:'contain'}}/>
                            </View>
                            {/*<View style={{width:'273px',height:'207px',marginBottom:'10px'}}>*/}
                            {/*    <Image source={O4} style={{objectFit:'contain'}}/>*/}
                            {/*</View>*/}
                            {/*<View style={{width:'273px',height:'207px',marginBottom:'10px'}}>*/}
                            {/*    <Image source={O5} style={{objectFit:'contain'}}/>*/}
                            {/*</View>*/}
                            {/*<View style={{width:'273px',height:'207px',marginBottom:'10px'}}>*/}
                            {/*    <Image source={O6} style={{objectFit:'contain'}}/>*/}
                            {/*</View>*/}
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.footer}>
                        <View>
                            <Text style={styles.footerText}>MyLease.pl</Text>
                            <Text style={styles.footerText}>ul. Wodna 15, 88-100 Inowrocławe</Text>
                        </View>
                        <View>
                            <Text style={styles.footerText}><FontAwesomeIcon icon={faGlobeEurope}/>www.mylease.pl</Text>
                        </View>
                    </View>
                    <View style={styles.footer2}>
                        <Text style={styles.footer2Text}>Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłacznie informacyjny. Ostateczne warunki umowy zaleza od oceny zdolnosci leasingowej
                            Leasingobiorcy oraz finansowanego przedmiotu i zostana uregulowane w umowie leasingu.</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Pdf = () => {
    let query = useQuery();
    return (
        <>
        <PDFViewer style={{width:'100%',height:'100vh'}}>
            <MyDocument
                state={{
                    user:{
                        name:'MyLease.pl',
                        phone:'+48 500 615 800',
                        email:'biuro@mylease.pl'
                    },
                    client:query.get("name"),
                    typeName:'',
                    financeType:'Leasing',
                    price:query.get("price")
                }}
                leasing={{
                    rate:query.get("rate"),
                    period:query.get("period"),
                    contribution:{
                        value:query.get("payment"),
                        type:'%'
                    },
                    repurchase:{
                        value:query.get("repurchase"),
                        type:'%'
                    },
                    otherOptions:[]
                }}
            />
        </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    page2: {
        height:'100%',
        flexDirection:'column',
        justifyContent:'space-between',
        alignItems:'center'
    },
    allCosts: {
        fontFamily: 'Montserrat',
        fontSize:11,
        color:'#27313f'
    },
    tableTextHeader: {
        fontFamily: 'Montserrat',
        fontSize:12,
        color:'white',
        marginBottom:2,
        textAlign:'center'
    },
    row: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'#27313f',
        textAlign:'center',
    },
    column: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'#27313f',
        textAlign:'center',
    },
    tableRow: {
        flexDirection:'row',
        justifyContent:'center',
        paddingVertical:5,
        borderBottom:'1px solid #27313f'
    },
    tableColumns: {
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'#d9d9d9',
        paddingVertical:2,
    },
    tableHeader: {
        padding:3,
        backgroundColor: 'rgb(54,118,181)',
        borderRadius:3
    },
    footer2Text: {
        fontFamily: 'MontserratLight',
        fontSize:7,
        color:'white',
        marginBottom:2,
        textAlign:'center'
    },
    footerText: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'white',
        marginBottom:2
    },
    footer2: {
        backgroundColor:'rgb(42,49,60)',
        padding:10,
        justifyContent:'center',
        alignItems: 'center',
        flexDirection:'row'
    },
    footer: {
        backgroundColor:'rgb(42,49,60)',
        padding:15,
        paddingBottom:10,
        justifyContent:'space-between',
        alignItems: 'center',
        flexDirection:'row'
    },
    priceSubText: {
        fontFamily: 'Montserrat',
        fontSize:15,
        color:'white',
        textAlign:'center'
    },
    priceTextBrutto: {
        fontFamily: 'Montserrat',
        fontSize:18,
        color:'white',
        textAlign:'center'
    },
    priceText: {
        fontFamily: 'Montserrat',
        fontSize:30,
        color:'white',
        textAlign:'center'
    },
    radioText: {
        fontFamily: 'Montserrat',
        fontSize:10,
        color:'#27313f',
        textAlign:'center'
    },
    radioActive: {
        backgroundColor: 'rgb(54,118,181)'
    },
    sliderDrag: {
        width: 20,
        height: 20,
        backgroundColor:'white',
        position:'absolute',
        right:-10,
        top:0,
        borderRadius:'50%'
    },
    slider: {
        width:'50%',
        height:20,
        backgroundColor: 'rgb(54,118,181)',
        borderRadius:4,
        position:'relative',
    },
    radio: {
        flex:1,
        padding: 3,
        borderRadius: 3
    },
    sliderWrapper: {
        backgroundColor:'#bfbfbf',
        flexDirection:'row',
        marginTop:5,
        padding:0,
        borderRadius:3,
        position: 'relative',
    },
    radioWrapper: {
        backgroundColor:'white',
        flexDirection:'row',
        marginTop:5,
        padding:2,
        borderRadius:3
    },
    financeLabel: {
        fontFamily: 'Montserrat',
        fontSize:11,
        color:'#27313f'
    },
    primaryFinance: {
        flexDirection:'row',
        marginHorizontal: 20,
        marginTop:40
    },
    carRow: {
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-end',
        marginTop: 0,
    },
    carLabel: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'#27313f',
        margin:0,
        marginRight: 7
    },
    carText: {
        fontFamily: 'MontserratBold',
        fontSize:15,
        color:'#27313f',
        margin:0,
    },
    versionText: {
        fontFamily: 'Montserrat',
        fontSize:15,
        color:'rgb(100,100,100)',
        margin:0,
        marginBottom: 15
    },
    brandText: {
        fontFamily: 'MontserratBold',
        fontSize:28,
        color:'#27313f',
        margin:0
    },
    page2Title: {
        fontFamily: 'Montserrat',
        fontSize:18,
        textAlign:'center',
        color:'#27313f'
    },
    label: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'#27313f'
    },
    carInfo: {
        display:'flex',
    },
    car: {
        display:'flex',
        paddingLeft:20,
        marginTop:50
    },
    workerText: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'white'
    },
    workerLabel: {
        fontFamily: 'Montserrat',
        fontSize:10,
        color:'white',
        marginBottom:5
    },
    worker: {
        padding:20,
        marginRight:20,
        position:'relative'
    },
    page: {
        flexDirection: 'column',
        justifyContent: 'space-between'
        // backgroundColor: '#E4E4E4'
    },
    header2: {
        flexDirection: 'row',
        justifyContent:'space-between',
    },
    header: {
        flexDirection: 'row',
        justifyContent:'space-between',
        flexGrow: 1
    }
});

export default Pdf;
